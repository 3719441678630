import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

const requireComponent = require.context(
    "./components",
    false,
    /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            // Gets the file name regardless of folder depth
            fileName
                .split("/")
                .pop()
                .replace(/\.\w+$/, "")
        )
    );

    // Register component globally
    Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.config.productionTip = false;
//Vue.config.devtools = true; 

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");