import Vue from 'vue'
import VueRouter from 'vue-router'
import HelloWorld from '../components/HelloWorld.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        props: true,
        component: HelloWorld,
    },
    { path: '*', redirect: '/' }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
