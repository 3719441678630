import axios from 'axios'

const apiClient = axios.create({
    baseURL: './api/',
    withCredentials: false,
    //headers: {
    //    Authorization: 'Bearer ' + localStorage.getItem('user-token')
    //}
})

export default {
    getWeatherForeCast() {
        return apiClient.get('weatherforecast')
    }
}
